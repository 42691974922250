<script>
// import searchAddress from "@/components/searchAddress/index.vue";
import {isCellphone} from "@/common/js/Utils";
import {getSeatModel} from "@/data";
import dayjs from "dayjs";

export default {
    name: "timelyUseVehicle",
    // components: {searchAddress},
    data() {
        return {
            loading: false,
            importForm: {
                content: ''
            },
            form: {
                orderAmount: undefined,
                departure: undefined,
                destination: undefined,
                // departure: {
                //     city: '深圳市'
                // },
                // destination: {
                //     city: '深圳市'
                // },
                tripUserName: undefined,
                tripUserMobile: undefined,
                reserveTime: undefined,
                driverGuiderNo: undefined
            },
            driverList: [],
            remoteLoading: false,
            rules: {
                orderAmount: [{required: true, message: '请输入订单金额', trigger: 'blur'}],
                departure: [{required: true, message: '请输入到达地址', trigger: 'blur'}],
                destination: [{required: true, message: '请输入到达地址', trigger: 'blur'}],
                tripUserName: [{required: true, message: '请输入出行人姓名', trigger: 'blur'}],
                tripUserMobile: [{
                    required: true,
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value)
                            return callback('请输入出行人手机号')
                        if (!isCellphone(value))
                            return callback('请输入正确的出行人手机号')
                        callback();
                    }
                }],
                reserveTime: [{required: true, message: '请输入出行时间', trigger: 'blur'}],
                driverGuiderNo: [{required: true, message: '请输入司机信息', trigger: 'blur'}],

            },
            driverInfo: undefined
        }
    },
    methods: {
        handAddressChange(field, val) {
            this.form[field] = val;
            if (val.value)
                this.$refs["form"].clearValidate(field);
        },
        async remoteMethod(query) {
            if (query) {
                this.remoteLoading = true;
                const ret = await this.http('/galaxyOrder/getDriverListBySupplier', {
                    // driverMobile: query,
                    driverName: query
                }, 'POST')
                if (ret.success && ret.data) {
                    this.driverList = ret.data
                } else {
                    this.driverList = [];
                }
                this.remoteLoading = false
            }
        },
        async handleSave() {
            try {
                this.loading = true
                await this.$refs['form'].validate()
                await this.$confirm('确定提交订单？', '提示')
                const ret = await this.http('/galaxyOrder/createOrder', {
                    ...this.form,
                    departure: '深圳市' + this.form.departure,
                    destination: '深圳市' + this.form.destination,
                    reserveTime: dayjs(this.form.reserveTime).valueOf()
                }, 'POST')
                if (ret.success && ret.data) {
                    this.$message.success('创建成功！')
                    this.reset()
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        handleDriverInfoChange(id) {
            this.driverInfo = this.driverList.find((item) => item.id === id)
            console.log(this.driverInfo);
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
                // console.log(info, type);
            }
            return info;
        },
        reset() {
            this.form = {
                orderAmount: undefined,
                departure: undefined,
                destination: undefined,
                // departure: {
                //     city: '深圳市'
                // },
                // destination: {
                //     city: '深圳市'
                // },
                tripUserName: undefined,
                tripUserMobile: undefined,
                reserveTime: undefined,
                driverGuiderNo: undefined
            }
            this.driverInfo = undefined
        },
        async handleImport() {
            await this.$refs['importFormRef'].validate()
            const data = this.importForm.content.split('\t')
            console.log(data);
            if (data.length !== 11)
                this.$message.error('导入内容格式错误')
            this.form = {
                orderAmount: data[6],
                departure: data[1],
                destination: data[2],
                tripUserName: data[3],
                tripUserMobile: data[4],
                reserveTime: data[5],
                driverGuiderNo: this.form.driverGuiderNo
            }
        }
    }
}
</script>

<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont">
            <el-form label-width="120px" ref="form" :model="form" :rules="rules" class="form" v-loading="loading">
                <el-form-item label="出发地址" prop="departure">
                    <el-input placeholder="请输入出发地址" v-model="form.departure">
                        <template slot="prepend">深圳市</template>
                    </el-input>
                    <!--                    <search-address-->
                    <!--                        isSlot-->
                    <!--                        :address="form.departure"-->
                    <!--                        input-width="660px"-->
                    <!--                        placeholder="请输入出发地点"-->
                    <!--                        @update:searchAddress="handAddressChange('departure',$event)"></search-address>-->
                </el-form-item>
                <el-form-item label="到达地址" prop="destination">
                    <el-input placeholder="请输入出发地址" v-model="form.destination">
                        <template slot="prepend">深圳市</template>
                    </el-input>
                    <!--                    <search-address-->
                    <!--                        isSlot-->
                    <!--                        :address="form.destination"-->
                    <!--                        input-width="660px"-->
                    <!--                        placeholder="请输入到达地址"-->
                    <!--                        @update:searchAddress="handAddressChange('destination',$event)"></search-address>-->
                </el-form-item>
                <el-form-item label="出行人姓名" prop="tripUserName">
                    <el-input placeholder="请输入出行人姓名" v-model="form.tripUserName"></el-input>
                </el-form-item>
                <el-form-item label="出行人手机" prop="tripUserMobile">
                    <el-input placeholder="请输入出行人姓名" v-model="form.tripUserMobile" :maxlength="11"
                              type="tel"></el-input>
                </el-form-item>
                <el-form-item label="出行时间" prop="reserveTime">
                    <el-date-picker v-model="form.reserveTime" type="datetime" style="width: 100%"
                                    placeholder="选择出行时间" format="yyyy-MM-dd HH:mm"></el-date-picker>
                </el-form-item>
                <el-form-item label="订单金额" prop="orderAmount">
                    <el-input placeholder="请输入订单金额" type="number" v-model="form.orderAmount"></el-input>
                </el-form-item>
                <el-form-item label="服务司机" prop="driverGuiderNo">
                    <el-select
                        v-model="form.driverGuiderNo"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入司机名称"
                        :remote-method="remoteMethod"
                        style="width: 100%"
                        :loading="remoteLoading"
                        @change="handleDriverInfoChange">
                        <el-option
                            v-for="item in driverList"
                            :key="item.id"
                            :label="item.driverName+'-'+item.driverMobile"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    <div v-if="driverInfo">
                        <span style="font-weight: 600;">司导姓名：</span>
                        <span style="color: #319BF7">{{ driverInfo.driverName }}</span>
                        <span style="font-weight: 600;padding-left: 20px">司导手机号：</span>
                        <span style="color: #319BF7">{{ driverInfo.driverMobile }}</span>
                        <span style="font-weight: 600;padding-left: 20px">身份证：</span>
                        <span style="color: #319BF7">{{ driverInfo.idCardNumber }}</span>
                        <span style="font-weight: 600;padding-left: 20px">车牌号：</span>
                        <span style="color: #319BF7">{{
                                driverInfo.carNumberPlate
                            }}</span>
                        <div>
                            <span style="font-weight: 600;">品牌型号：</span>
                            <span style="color: #319BF7">{{ driverInfo.carBrand }}/{{ driverInfo.carModel }}</span>
                            <span style="font-weight: 600;padding-left: 20px">车型：</span>
                            <span style="color: #319BF7">{{
                                    driverInfo.carType ? formatCarModel(driverInfo.carType).name : ''
                                }}</span>
                            <span style="font-weight: 600;padding-left: 20px">颜色：</span>
                            <span style="color: #319BF7">{{ driverInfo.carColor }}</span>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button style="width: 100%" type="primary" :loading="loading" @click="handleSave">保存
                    </el-button>
                </el-form-item>
            </el-form>
            <div style="margin-left: 20px">
                <el-form :model="importForm" ref="importFormRef">
                    <el-form-item prop="content" :rules="[{required:true,message:'请输入内容'}]">
                        <el-input style="width: 400px;" rows="10" type="textarea"
                                  placeholder="导入内容" v-model="importForm.content"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" style="width: 400px" @click="handleImport">导入</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-main>
</template>

<style scoped lang="scss">
.eTrip-section-cont {
    background-color: #fff;
    margin: 10px 0;
    padding: 20px 0;
    display: flex;


    .form {
        width: 780px;
    }
}
</style>
